const { TweenMax, ScrollToPlugin, Power3 } = require("gsap");
// require("gsap/src/uncompressed/plugins/ScrollToPlugin")
var $ = require( "jquery" );
var jQuery = $;
/**
 * scroll ventana a objeto indicado
 * @param {[type]} _duration  [duracion del scroll]
 * @param {[type]} _targetDOM [objeto a donde va]
 * @param {[type]} _delay     [retraso en ejecución del scroll]
 * @param {[type]} _offset     [distancia extra movimiento]
 */
module.exports.PLG_scrollTo = function (_duration,_targetDOM,_delay,_offset){
    TweenMax.to(window, _duration, {scrollTo:{y:jQuery(_targetDOM).offset().top+_offset,autoKill:false},delay:_delay,ease:Power3.easeInOut});
}

/**
 * [PLG_showModalAlerta description]
 * @param {[type]} _titulo [description]
 * @param {[type]} _texto  [description]
 */
module.exports.PLG_showModalAlerta = function (_titulo,_texto){
    jQuery("#modal-alerta .modal-title").html(_titulo);
    jQuery("#modal-alerta .modal-body").html(_texto);
    jQuery("#modal-alerta").modal("show");
}

/**
 * [PLG_showModalAlerta :: mostrar tip con texto apuntando al objeto]
 * @param {[type]} _texto     [texto dentro del tip]
 * @param {[type]} _objTarget [objeto donde apunta la flecha]
 */
module.exports.PLG_showModalTip = function (_texto,_objTarget){
    jQuery("#modal-tip .modal-body").html(_texto);
    var elementoY=jQuery(_objTarget).offset().top-jQuery(document).scrollTop()+jQuery(_objTarget).outerHeight();
    var elementoX=jQuery(_objTarget).offset().left+(jQuery(_objTarget).outerWidth()/2);
    TweenMax.fromTo("#modal-tip .modal-dialog", 0.5, {
    top:elementoY+30,
    left:elementoX,
    opacity:0
    },{
    top:elementoY,
    left:elementoX,
    autoAlpha:1,
    display:'block',
    ease:Power4.easeOut
    });
    jQuery("#modal-tip").modal("show");
}


/**
 * [checkCookie description]
 * @return {[type]} [description]
 */
module.exports.PLG_checkCookie = function () {
    var user = getCookie("accept.cookies");
    if (user != "") {
        //hay cookies 
    } else {
        jQuery("#modal-cookies").fadeIn();
    }
}
/**
 * [setCookie description]
 * @param {[type]} cname  [description]
 * @param {[type]} cvalue [description]
 * @param {[type]} exdays [description]
 */
module.exports.PLG_setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
/**
 * [getCookie description]
 * @param  {[type]} cname [description]
 * @return {[type]}       [description]
 */
// const getCookie = function (cname) {
//     var name = cname + "=";
//     var decodedCookie = decodeURIComponent(document.cookie);
//     var ca = decodedCookie.split(';');
//     for(var i = 0; i <ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) == ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return "";
// }

/**
 * [feedback mensaje feedback ajax para un botón::: requiere un contenedor "js-contenedor-feedback"]
 * @param  {[type]} _titutlo [description]
 * @param  {[type]} _texto   [description]
 * @param  {[type]} _tipo    [description]
 */
module.exports.PLG_feedback = function(_titutlo,_texto,_tipo){
	var contenedor=jQuery(".js-contenedor-feedback");
		contenedor.html('<div class="alert alert-'+_tipo+' fade mt-3 show" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button><strong>'+_titutlo+'</strong> '+_texto+'</div>');
}

/**
 * [copyToClipboard description]
 * @param  {[type]} elem [description]
 * @return {[type]}      [description]
 */
module.exports.PLG_copyToClipboard = function (elem) {
	  // create hidden text element, if it doesn't already exist
    var targetId = "_hiddenCopyText_";
    var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
    var origSelectionStart, origSelectionEnd;
    if (isInput) {
        // can just use the original source element for the selection and copy
        target = elem;
        origSelectionStart = elem.selectionStart;
        origSelectionEnd = elem.selectionEnd;
    } else {
        // must use a temporary form element for the selection and copy
        target = document.getElementById(targetId);
        if (!target) {
            var target = document.createElement("textarea");
            target.style.position = "absolute";
            target.style.left = "-9999px";
            target.style.top = "0";
            target.id = targetId;
            document.body.appendChild(target);
        }
        target.textContent = elem.textContent;
    }
    // select the content
    var currentFocus = document.activeElement;
    target.focus();
    target.setSelectionRange(0, target.value.length);
    
    // copy the selection
    var succeed;
    try {
    	succeed = document.execCommand("copy");
    } catch(e) {
        succeed = false;
    }
    // restore original focus
    if (currentFocus && typeof currentFocus.focus === "function") {
        currentFocus.focus();
    }
    if (isInput) {
        // restore prior selection
        elem.setSelectionRange(origSelectionStart, origSelectionEnd);
    } else {
        // clear temporary content
        target.textContent = "";
    }
    return succeed;
}