import { WOW } from 'wowjs';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

const plg = require("../lib/functions-comunes");

var $ = require( "jquery" );
var jQuery = $;
/**
 * [wow description]
 * @type {Wow}
 */
 var wow = new WOW({
  boxClass: 'wow',
  animateClass: 'animated',
  offset: 0,
  mobile: true
})
wow.init();

/**
 * [swiper description]
 * @type {Swiper}
 */

const swiper = new Swiper(".swiper", {
    // Optional parameters

    slidesPerView: 4,
    spaceBetween: 15,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.2,
        spaceBetween: 15
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1.2,
        spaceBetween: 15
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      860: {
        slidesPerView: 4,
        spaceBetween: 15
      }      
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
});
// swiper.init();


/**
 * LISTENER menus con ancla
 * @param  {[type]} e){	e.preventDefault();	var ancla         [description]
 * @return {[type]}                              [description]
 */
$(".js-menu-ancla").click(function(e) {
  e.preventDefault();
  var ancla = $(this).attr("href");
  var offset = $(this).data("menuancla-offset");
  if (offset == "" || offset == undefined) {
    offset = 0;
  }
  plg.PLG_scrollTo(1, ancla, 0, offset, 0);
});

/**
 * LISTENER para mostrar modal alerta
 */
$(".js-modal-alerta").click(function(e) {
  e.preventDefault();
  plg.PLG_showModalAlerta(
    $(this).data("alerta-titulo"),
    $(this).data("alerta-texto")
  );
});

/**
 * LISTENER cierre cookies
 */
// $("#modal-cookies .close").click(function() {
//   $("#modal-cookies").fadeOut();
//   plg.PLG_setCookie("accept.cookies", "true", 365);
// });
// plg.PLG_checkCookie();


/**
 * COOKIES
 */
 function checkCookie() {
  var user = getCookie("lamareanuestro-cookies-tecnica");
  if (user != "") {
      //hay cookies 
      //console.log('tiene cook');
      $("#modal-cookies").fadeOut();
  } else {
      $("#modal-cookies").fadeIn();
      console.log('no tiene');
  }
} 
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

  /**
   * LISTENER cierre cookies
   */
   $("#modal-cookies #close-cookies").click(function(){
    $("#modal-cookies").fadeOut();
    setCookie("lamareanuestro-cookies-tecnica", "true", 365);
  });
  checkCookie();

/**
 * LISTENER copiar de un input
 */
$(".js-btn-copy").click(function() {
  var target = $(this).data("target-copy");
  plg.PLG_copyToClipboard(document.getElementById(target));
});

$('.js-configurar').on('click', function(){
  $('.js-formulario-cookies').show();
});
$('#btn-acepto').on('click', function(){
  $('#cookie_estadisticas').prop('checked', true);
  $('#formCookies').submit();
});
$('#btn-rechazo').on('click', function(){
$('#cookie_estadisticas').prop('checked', false);
$('#formCookies').submit();
});

$('#formCookies').submit(function( event ) {
  
  event.preventDefault();

  $.ajax({
    method: "POST",
    url: $("#formCookies").attr("action"),
    data: $("#formCookies").serialize()
  })
  .done(function( msg ) {
    resultadoCookies(JSON.parse(msg));
  });

});

function resultadoCookies(result){
  console.log(result);
  
 if (result.resultado == 'OK' ){
   $("#modal-cookies").fadeOut();
   setCookie("lamareanuestro-cookies-tecnica", "true", 365);//la técnica la aprobamos por js como hasta ahora
   
   if (result.estadisticas == 'si' && result.codigoGA!=''){
     console.log('co::'+result.codigoGA);
     (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
       (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
       m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
       })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
     //'<?=GA_CODE?>'
       ga('create', result.codigoGA, 'auto');
       ga('send', 'pageview');

       $('.js-noactivarGA').addClass('js-activarGA');
       $('.js-noactivarGA').removeClass('js-noactivarGA');
       initGA();
    }    
 }
}

function initGA(){
  console.log("inicializo ga");
  $('.js-activarGA').on('click', function(){
    var pag = $(this).attr('data-pagina');
    var evento = $(this).attr('data-evento');
    ga('send', 'event', pag, 'CLICK', evento);
  });  
}

var cookieanalitica = getCookie("lamareanuestro-cookies-estadisticas");
if (cookieanalitica != "") {
  $('.js-noactivarGA').addClass('js-activarGA');
  $('.js-noactivarGA').removeClass('js-noactivarGA');

  console.log("tambien estadísticas");

}
initGA();