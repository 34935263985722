var $ = require( "jquery" );
// const bootstrap = require('bootstrap');

import "./lib/functions-comunes.js";
import "./lib/functions-initlisteners.js";

// FontAwesome 5 PRO
// import '@fortawesome/fontawesome-pro/js/fontawesome'
// import '@fortawesome/fontawesome-pro/js/solid'
// import '@fortawesome/fontawesome-pro/js/regular'
// import '@fortawesome/fontawesome-pro/js/brands'
// import '@fortawesome/fontawesome-pro/js/light'
//end FontAwesome

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  // aboutUs,
});

$(document).ready(() => routes.loadEvents());